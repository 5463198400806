import {
  CONFIG_CALL_TO_ACTION_DEFAULT,
  CONFIG_FORM_CAPTURE_DEFAULT,
} from '../constants';
import { createSelector } from '@reduxjs/toolkit';
import {
  selectDetailProject,
  selectLanguageProjectSelected,
} from './detailProjectSelectors';
import { getTextMultiLang } from '@utils/helper';
import { selectDefaultLanguageProject } from './multiLanguageSelector';

export const selectHotspotConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.hotspotConfig || undefined,
);

export const selectPolygonConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.polygonConfig || undefined,
);

export const selectThemeConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.themeConfig || undefined,
);

export const selectPopupConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.popupConfig || undefined,
);

export const selectFormCaptureConfigGlobal = createSelector(
  [selectDetailProject],
  project => ({
    ...CONFIG_FORM_CAPTURE_DEFAULT,
    ...project?.config?.design?.formCaptureConfig,
  }),
);

export const selectCallToActionConfigGlobal = createSelector(
  [selectDetailProject],
  project => ({
    ...CONFIG_CALL_TO_ACTION_DEFAULT,
    ...project?.config?.design?.callToActionConfig,
  }),
);

export const selectMetaDataProject = createSelector(
  [
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (project, languageProjectSelected, defaultLanguageProject) =>
    project?.config?.marketing?.meta_data
      ? {
          ...project?.config?.marketing?.meta_data,
          title: getTextMultiLang(
            project?.config?.marketing?.meta_data?.title,
            project?.keyTranslations,
            languageProjectSelected,
            defaultLanguageProject,
          ),
          description: getTextMultiLang(
            project?.config?.marketing?.meta_data?.description,
            project?.keyTranslations,
            languageProjectSelected,
            defaultLanguageProject,
          ),
        }
      : undefined,
);

export const selectGATrackingProject = createSelector(
  [selectDetailProject],
  project => project?.config?.marketing?.analytic?.uid || undefined,
);

export const selectGATagTrackingProject = createSelector(
  [selectDetailProject],
  project =>
    project?.config?.marketing?.analytic?.ggTag ||
    process.env.NEXT_PUBLIC_GA_TAG_DEFAULT ||
    undefined,
);

export const selectScriptChats = createSelector(
  [selectDetailProject],
  project => project?.config?.marketing?.script_chats || undefined,
);

export const selectConfigLittlePlanetProject = createSelector(
  [selectDetailProject],
  project => project?.config?.setting?.intro_little_planet || undefined,
);

export const selectConfigProjectionModeProject = createSelector(
  [selectDetailProject],
  project => project?.config?.setting?.projection_mode || undefined,
);

export const selectConfigControlModeProject = createSelector(
  [selectDetailProject],
  project =>
    project?.config?.setting?.control_mode || ({ mode: 'drag' } as const),
);

export const selectConfigFollowModeProject = createSelector(
  [selectDetailProject],
  project => project?.config?.setting?.follow_mode || { enable: true },
);

export const selectConfigFirstSceneProject = createSelector(
  [selectDetailProject],
  project => project?.config?.setting?.general?.first_scene || undefined,
);

export const selectLanguageProject = createSelector(
  [selectDetailProject],
  project => project?.config?.setting?.general?.language || 'en',
);
